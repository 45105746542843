import React, { useState, useContext, useEffect } from "react"
import * as Yup from "yup"
import {
  Box,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core"
import clsx from "clsx"
import MomentUtils from "@date-io/moment"
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers"
import moment from "moment"
import Sliders from "../Sliders"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../../FormErrors"
import Button from "../../../Button"
import SupervisedUserCircleOutlinedIcon from "@material-ui/icons/SupervisedUserCircleOutlined"
import AccountBoxOutlinedIcon from "@material-ui/icons/AccountBoxOutlined"
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined"
import ThumbDownAltOutlinedIcon from "@material-ui/icons/ThumbDownAltOutlined"
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined"

import loanContext from "../../../../context/loan/loanContext"

import { useStyles } from "./style"

const EligibilityForm = () => {
  const classes = useStyles()
  const {
    isSubmitting,
    handleEligibilityNextStep,
    loading,
    getProviders,
    providers,
    checkEligibility,
    eligibility: { activeStep },
  } = useContext(loanContext)

  useEffect(() => {
    providers.length < 1 && getProviders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [reason, setReason] = useState("")
  const [meansOfIdentification, setMeansOfIdentification] = useState("")
  const [statementOfAccount, setStatementOfAccount] = useState("")
  const [hoveredReason, setHoveredReason] = useState("")
  const [hoveredMeans, setHoveredMeans] = useState("")
  const [hoveredStatement, setHoveredStatement] = useState("")
  const [sliderValues, setSliderValues] = useState({
    monthlyExpenses: 60,
    tenor: 3,
  })

  const { monthlyExpenses, tenor } = sliderValues

  const whoFor = [
    {
      title: "For Me",
      icon: (
        <AccountCircleOutlinedIcon
          classes={{ root: classes.icon }}
          className={
            hoveredReason === 0 || reason === "For Me" ? classes.selected : ""
          }
        />
      ),
    },
    {
      title: "My Child",
      icon: (
        <SupervisedUserCircleOutlinedIcon
          classes={{ root: classes.icon }}
          className={
            hoveredReason === 1 || reason === "My Child" ? classes.selected : ""
          }
        />
      ),
    },
    {
      title: "Somebody I'm sponsoring",
      icon: (
        <AccountBoxOutlinedIcon
          classes={{ root: classes.icon }}
          className={
            hoveredReason === 2 || reason === "Somebody I'm sponsoring"
              ? classes.selected
              : ""
          }
        />
      ),
    },
  ]

  const headerText = activeStep === 1 ? "About The Loan" : "About You"
  const providerKey =
    providers.length > 0 &&
    providers.find(provider => provider.bankName === "CredPal").id

  const handleChangeMonthlyExpenses = (e, val) => {
    setSliderValues({ ...sliderValues, monthlyExpenses: val })
  }

  const handleChangeTenor = (e, val) => {
    setSliderValues({ ...sliderValues, tenor: val })
  }

  const initialValues = {
    monthlyIncome: "",
    // monthlyExpenses: "",
    reason,
    // tenor: "",
    dateOfBirth: new Date(),
    meansOfIdentification: "",
    statementOfAccount: "",
  }

  const aboutYouValidationSchema = Yup.object({
    monthlyIncome: Yup.number().required("Monthly Income is empty"),
    // monthlyExpenses: Yup.number()
    //   .max(100, "maximum is 100%")
    //   .required("Monthy expenses is empty"),
    reason: Yup.string().required("Select an option"),
    // tenor: Yup.number()
    //   .min(1, `Minimum loan duration is 1 month`)
    //   .max(12, `Maximum loan tenure is 12 months`)
    //   .required("Loan Duration is empty"),
  })

  const aboutLoanValidationSchema = Yup.object({
    // dateOfBirth: Yup.date()
    //   .max(new Date(Date.now() - 86400000), "Date must be in the past")
    dateOfBirth: Yup.string().nullable()
      .test("DateOfBirth", "Age should be between 18 and 59 years", value => {
        return moment().diff(moment(value), "years") > 17
      })
      .test("DateOfBirth", "Age should be between 22 and 59 years", value => {
        return moment().diff(moment(value), "years") < 60
      })
      .required("Select date Of Birth"),

    meansOfIdentification: Yup.string().required("Select an option"),
    statementOfAccount: Yup.string().required("Select an option"),
  })


  const onSubmit = (values, resetForm, setTouched) => {
    setTouched({})
    if (activeStep === 1) {
      return handleEligibilityNextStep()
    }
    values = { providerKey, ...values, monthlyExpenses, tenor }
    checkEligibility(values, resetForm)

  }

  return (
    <Box marginTop={"1rem"} marginBottom={"1rem"}>
      <Box className={classes.header}>
        <Typography variant="h4">{headerText}</Typography>
      </Box>
      <Formik
        initialValues={initialValues}
        validationSchema={
          activeStep === 1
            ? aboutYouValidationSchema
            : aboutLoanValidationSchema
        }
        onSubmit={async (initialValues, { resetForm, setTouched  } ) => {
          onSubmit(initialValues, resetForm, setTouched )
        }}
      >
        {({ getFieldProps, setFieldValue, values }) => (
          <Form noValidate autoComplete="off">
            {/* {activeStep === 1 ? ( */}
            {/* //this mitigates against the formik error display when off the right component */}
            <Box style={{ display: activeStep === 1 ? "block" : "none" }}>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>
                  How much do you earn monthly?
                </Typography>
                <TextField
                  name="monthlyIncome"
                  id="monthlyIncome"
                  type="number"
                  className={classes.numberInput}
                  {...getFieldProps("monthlyIncome")}
                  variant="outlined"
                  size="small"
                />
                <Typography variant="caption">Amount in Naira</Typography>
                <ErrorMessage name="monthlyIncome" component={FormErrors} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>
                  How much of (in %) your income do you spend monthly?
                </Typography>
                {/* <TextField
                    name="monthlyExpenses"
                    id="monthlyExpenses"
                    type="number"
                    className={classes.numberInput}
                    {...getFieldProps("monthlyExpenses")}
                    variant="outlined"
                    size="small"
                  /> */}
                <Sliders
                  handleSliderChange={handleChangeMonthlyExpenses}
                  defaultValue={60}
                  min={1}
                  max={100}
                  // value={`${monthlyExpenses}%`}
                />
                <ErrorMessage name="monthlyExpenses" component={FormErrors} />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>Who is the loan for?</Typography>
                <Box className={classes.optionsContainer}>
                  {whoFor.map((who, i) => {
                    const { title, icon } = who
                    return (
                      <Box
                        key={i}
                        className={i === 2 ? classes.last : classes.optionItem}
                        style={{
                          backgroundColor: reason === title ? "#E8EFFD" : "",
                          border: reason === title ? "1px solid #117AF3" : "",
                        }}
                        onClick={() => {
                          setReason(title)
                          setFieldValue("reason", title)
                        }}
                        onMouseEnter={() => {
                          setHoveredReason(i)
                        }}
                        onMouseLeave={() => setHoveredReason("")}
                      >
                        <Box>
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "0.5rem",
                            }}
                          >
                            {icon}
                          </Box>
                          <Typography gutterBottom>{title}</Typography>
                        </Box>
                      </Box>
                    )
                  })}
                  <ErrorMessage name="reason" component={FormErrors} />
                </Box>
                <Box style={{ visibility: "hidden" }}>
                  <TextField
                    name="reason"
                    id="reason"
                    className={classes.numberInput}
                    {...getFieldProps("reason")}
                    value={reason}
                    variant="outlined"
                    size="small"
                  />
                </Box>
              </FormControl>
              <FormControl style={{ width: "100%", marginTop: "1rem" }}>
                <Typography gutterBottom>
                  {/* How long do you want to take the loan for? */}
                  Loan Tenure? (Months)
                </Typography>
                {/* <TextField
                    name="tenor"
                    id="tenor"
                    type="number"
                    className={classes.numberInput}
                    {...getFieldProps("tenor")}
                    variant="outlined"
                    size="small"
                  /> */}
                <Sliders
                  handleSliderChange={handleChangeTenor}
                  defaultValue={3}
                  // value={tenor}
                  min={1}
                  max={6}
                />
                <Typography variant="caption">
                  Accepted loan duration is between 1 to 6 months
                </Typography>
                <ErrorMessage name="tenor" component={FormErrors} />
              </FormControl>
            </Box>
            {/* // ) : (  */}
            <Box style={{ display: activeStep === 2 ? "block" : "none" }}>
              <FormControl className={classes.formControl}>
                <Typography className={classes.label}>Date Of Birth (dd/mm/yyyy)</Typography>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    inputVariant="outlined"
                    format="DD/MM/yyyy"
                    id="dateOfBirth"
                    name="dateOfBirth"
                    size="small"
                    value={values.dateOfBirth}
                    onChange={value => {
                      setFieldValue("dateOfBirth", value)
                    }}
                    helperText={""}
                    KeyboardButtonProps={{
                      "aria-label": "change date",
                    }}
                  />
                </MuiPickersUtilsProvider>
                <Typography variant="caption">
                  Accepted Age range is between 18 - 59years of age
                </Typography>
                <ErrorMessage name="dateOfBirth" component={FormErrors} />
              </FormControl>

              <FormControl className={classes.formControl}>
                <Typography gutterBottom>
                  Do you have a valid means of Identification?
                </Typography>
                <Box className={classes.optionsContainer}>
                  <Box
                    className={
                      meansOfIdentification
                        ? clsx(classes.selectedBox, classes.optionItem)
                        : classes.optionItem
                    }
                    onClick={() => {
                      setMeansOfIdentification(true)
                      setFieldValue("meansOfIdentification", "yes")
                    }}
                    onMouseEnter={() => {
                      setHoveredMeans(true)
                    }}
                    onMouseLeave={() => setHoveredMeans("")}
                  >
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <ThumbUpAltOutlinedIcon
                          classes={{ root: classes.icon }}
                          className={
                            hoveredMeans || meansOfIdentification
                              ? classes.selected
                              : ""
                          }
                        />
                      </Box>
                      <Typography>Yes, I do</Typography>
                    </Box>
                  </Box>
                  <Box
                    className={
                      meansOfIdentification === false
                        ? clsx(classes.selectedBox, classes.optionItem)
                        : classes.optionItem
                    }
                    onClick={() => {
                      setMeansOfIdentification(false)
                      setFieldValue("meansOfIdentification", "no")
                    }}
                    onMouseEnter={() => {
                      setHoveredMeans(false)
                    }}
                    onMouseLeave={() => setHoveredMeans("")}
                  >
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <ThumbDownAltOutlinedIcon
                          classes={{ root: classes.icon }}
                          className={
                            hoveredMeans === false ||
                            meansOfIdentification === false
                              ? classes.selected
                              : ""
                          }
                        />
                      </Box>
                      <Typography>No, I don't</Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant="caption">
                  National ID, Voters Card, International Passport Or Driver’s
                  License
                </Typography>
                <ErrorMessage
                  name="meansOfIdentification"
                  component={FormErrors}
                />
              </FormControl>
              <FormControl className={classes.formControl}>
                <Typography gutterBottom>
                  Do you have a bank statement of account with over six months
                  transaction?
                </Typography>
                <Box className={classes.optionsContainer}>
                  <Box
                    className={
                      statementOfAccount
                        ? clsx(classes.selectedBox, classes.optionItem)
                        : classes.optionItem
                    }
                    onClick={() => {
                      setStatementOfAccount(true)
                      setFieldValue("statementOfAccount", "yes")
                    }}
                    onMouseEnter={() => {
                      setHoveredStatement(true)
                    }}
                    onMouseLeave={() => setHoveredStatement("")}
                  >
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <ThumbUpAltOutlinedIcon
                          classes={{ root: classes.icon }}
                          className={
                            hoveredStatement || statementOfAccount
                              ? classes.selected
                              : ""
                          }
                        />
                      </Box>
                      <Typography>Yes, I do</Typography>
                    </Box>
                  </Box>
                  <Box
                    className={
                      statementOfAccount === false
                        ? clsx(classes.selectedBox, classes.optionItem)
                        : classes.optionItem
                    }
                    onClick={() => {
                      setStatementOfAccount(false)
                      setFieldValue("statementOfAccount", "no")
                    }}
                    onMouseEnter={() => {
                      setHoveredStatement(false)
                    }}
                    onMouseLeave={() => setHoveredStatement("")}
                  >
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <ThumbDownAltOutlinedIcon
                          classes={{ root: classes.icon }}
                          className={
                            hoveredStatement === false ||
                            statementOfAccount === false
                              ? classes.selected
                              : ""
                          }
                        />
                      </Box>
                      <Typography>No, I don't</Typography>
                    </Box>
                  </Box>
                </Box>
                <Typography variant="caption">
                  Your bank statement would be required
                </Typography>
                <ErrorMessage
                  name="statementOfAccount"
                  component={FormErrors}
                />
              </FormControl>
            </Box>
            {/* )} */}

            <Box>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                disabled={(activeStep === 2 && loading) || isSubmitting}
                loading={activeStep === 2 && isSubmitting}
                marginTop={"6rem"}
                type="submit"
                marginBottom={"1rem"}
              >
                {activeStep === 1 ? "Next" : "Calculate"}
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  )
}

export default EligibilityForm