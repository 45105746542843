import React, { useContext, useEffect } from "react"
import { Box, Typography } from "@material-ui/core"
import ArrowBackIcon from "@material-ui/icons/ArrowBack"
import Paper from "../../Paper"

import EligibilityIntro from "./EligibilityIntro"
import EligibilityFeedback from "./EligibilityFeedback"
import EligibilityForm from "./EligibilityForm"

import loanContext from "../../../context/loan/loanContext"

import { useStyles } from "./style"

const EligibilityCheckLayout = () => {
  const classes = useStyles()
  const {
    resetEligibilityFormStep,
    handleEligibilityPreviousStep,
    eligibility: { activeStep },
  } = useContext(loanContext)

  useEffect(() => {
    //to make sure the user is taken back to top of the page
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    })
  }, [activeStep])

  useEffect(() => {
    activeStep !== 0 && resetEligibilityFormStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const { activeStep } = eligibility
  const getActiveStepContent = () => {
    switch (activeStep) {
      case 0:
        return <EligibilityIntro />

      case 1:
        case 2:
        return <EligibilityForm />

      case 3:
        return <EligibilityFeedback />

      default:
        return <EligibilityIntro />
    }
  }

  return (
    <div className={classes.root}>
      <Box className={classes.eligibilityBox} paddingTop={"3rem"}>
        {activeStep === 0 && (
          <Typography
            variant="h2"
            align="center"
            gutterBottom
            style={{ color: "#ffffff" }}
          >
            Eligibility Check
          </Typography>
        )}
        <Box marginTop={"2rem"}>
          {activeStep > 0 && activeStep < 3 && (
            <Box
              className={classes.backAction}
              onClick={handleEligibilityPreviousStep}
            >
              <ArrowBackIcon fontSize="small" />{" "}
              <Typography style={{ marginLeft: "0.5rem" }}>Back</Typography>
            </Box>
          )}
          <Paper padding="1rem 3rem 2rem" borderRadius="2px">
            {getActiveStepContent()}
          </Paper>
        </Box>
      </Box>
    </div>
  )
}

export default EligibilityCheckLayout
