import React, { useContext } from "react"
import { Box, Typography } from "@material-ui/core"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import Button from "../../../Button"
import loanContext from "../../../../context/loan/loanContext"

import { useStyles } from "./style"

const EligibilityIntro = () => {
  const classes = useStyles()

  const { handleEligibilityNextStep } = useContext(loanContext)

  const introDetails = [
    "Takes about 1 minute to complete",
    "No need to upload documents",
    "We won't ask you too many “yeye\" questions",
    "Regardless of your income, there’s a loan for you.",
  ]
  return (
    <Box marginTop={"3rem"} marginBottom={"1rem"}>
      <Typography variant="body2">
        Hey There! Let’s see if you’re eligible for a loan and how much you can
        get from any of our Loan providers
      </Typography>
      <Box className={classes.introOptions}>
        {introDetails.map((detail, i) => (
          <Box key={i} style={{ display: "inline-flex", marginTop: "1rem" }}>
            <CheckCircleOutlineIcon color="primary" fontSize="small" />
            <Typography style={{ marginLeft: "1rem" }}>{detail}</Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        color="primary"
        fullWidth
        marginTop={"4rem"}
        marginBottom={"1rem"}
        onClick={handleEligibilityNextStep}
      >
        Start
      </Button>
    </Box>
  )
}

export default EligibilityIntro
