import { makeStyles } from "@material-ui/core/styles"
import eligibilityImage from "../../../images/eligibility-background.jpg"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "8rem",
    // height: "100vh",
    paddingBottom: "8rem",
    backgroundImage: `linear-gradient(0deg, rgba(50, 100, 200, 0.9), rgba(50, 100, 200, 0.9)), url(${eligibilityImage});`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  eligibilityBox: {
    margin: "0rem auto",
    // border: "2px solid green",
    width: "30rem",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  whiteText: {
    color: "#ffffff",
  },
  backAction: {
    display: "inline-flex",
    alignItems: "center",
    color: "#ffffff",
    marginBottom: "0.8rem",
    "&:hover": {
      cursor: "pointer",
    },
  },
}))

export { useStyles }
