import React, { useContext } from "react"
import { Box, Typography } from "@material-ui/core"
import { Link } from "gatsby"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"
import Button from "../../../Button"
import loanContext from "../../../../context/loan/loanContext"
import AuthContext from "../../../../context/auth/authContext"

import { useStyles } from "./style"

const EligibilityFeedback = () => {
  const {
    resetEligibilityFormStep,
    eligibility: { status, feedBack },
  } = useContext(loanContext)
  const classes = useStyles(status)
  const { isAuthenticated } = useContext(AuthContext)
  const link = isAuthenticated ? "/dashboard" : "/user/login"

  return (
    <Box marginTop={"3rem"} marginBottom={"1rem"} align="center">
      <Box>
        <Box className={classes.statusIconContainer} marginBottom={"1.5rem"}>
          <Box className={classes.statusIcon}>
            {status ? (
              <CheckIcon fontSize="large" />
            ) : (
              <CloseIcon fontSize="large" />
            )}
          </Box>
        </Box>
        <Typography variant="h4">
          You are {!status && "not"} Eligible
        </Typography>
        <Box marginTop={"2rem"}>
          {status ? (
            <Box>
              <Typography gutterBottom>
                You're eligible to take up to{" "}
                <span style={{ color: "#0F42A4" }}>
                  <b>NGN{feedBack.eligibilityLoanAmount}</b>
                </span>{" "}
                from any of our loan providers.
              </Typography>
              <Typography style={{ marginTop: "1rem" }}>
                Your repayment for {feedBack.tenor} month
                {feedBack.tenor > 1 && "s"} is{" "}
                <span style={{ color: "#0F42A4" }}>
                  <b>NGN{feedBack.monthlyRepayment}/month</b>
                </span>
              </Typography>
            </Box>
          ) : (
            <Typography>{feedBack}</Typography>
          )}
        </Box>
        {status ? (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            marginTop={"2rem"}
            marginBottom={"1rem"}
            component={Link}
            to={link}
          >
            Apply Now
          </Button>
        ) : (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={resetEligibilityFormStep}
            marginTop={"2rem"}
            marginBottom={"1rem"}
          >
            Done
          </Button>
        )}
        {/* <Box marginTop={"2rem"}> */}
        <Typography variant="caption">
          This Calculator is an indicative tool that checks your eligibility and
          calculates how much you can borrow. Results are approximations as
          interest rate applied is indicative. Actual rate depends on the fund
          provider selected at the point of application.
        </Typography>
        {/* </Box> */}
      </Box>
    </Box>
  )
}

export default EligibilityFeedback
